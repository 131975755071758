import React from 'react'
import Figure from './figure'
import BlockContent from './block-content'

const Physician = ({ image, position, name, description, ...props }) => {
  const showIntercom = (e) => {
    e.preventDefault()

    if (typeof window.Intercom !== 'undefined') {
      window.Intercom('show')
    }
  }

  return (
    <div className='mx-auto flex flex-col md:flex-row items-center text-center md:text-left mb-16 max-w-screen-md'>
      <div className='w-32 md:w-56 mb-4'>
        {image && (
          <Figure className='overflow-hidden rounded-full' node={image} aspectRatio={1} />
        )}
      </div>

      <div className={`flex flex-col justify-center md:ml-12 w-full md:w-3/5`}>
        {position && (
          <div className='text-xs'>
            {position}
          </div>
        )}
        {name && (
          <div className='text-primary font-medium mb-4'>{name}</div>
        )}
        {description && (
          <BlockContent blocks={description} className='small' />
        )}
      </div>
    </div>
  )
}

export default Physician
