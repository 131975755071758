import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import SEO from '../components/seo'
import BlockContent from '../components/block-content'
import ProductCard from '../components/product-card'
import { BlockContentToText } from '../utils/block-content-to-text'
import Reviews from '../components/reviews'
import StarRating from '../components/star-rating'
import Button from '../components/button'
import Usp from '../components/usp'
import GlobalUSP from '../components/global-usp'
import Figure from '../components/figure'
import Marquee from '../components/marquee'
import { faqToAccordion, mapEdgesToNodes } from '../utils/helpers'
import Accordion from '../components/accordion'
import BlogPostPreview from '../components/blog-post-preview'
import Carousel from '../components/carousel'
import Physician from '../components/physician'
import styles from './category.module.css'
import paymentIcon from '../images/pay-circle.svg'
import Breadcrumbs from '../components/breadcrumbs'
import { ProductImpressions } from '../components/enhanced-ecommerce'

const CategoryTemplate = props => {
  const { data, errors } = props
  const category = data && data.category
  const products = data && data.products
  const blog = data && data.blog && mapEdgesToNodes(data.blog)
  const pageDescriptionBlock = data && data.site && data.site.pageDescriptionBlock
  const metaDescription = (category.seo && category.seo.description) || BlockContentToText(category.description)

  return (
    <>
      <Breadcrumbs crumbs={[ category ]} />
      <div className='max-h-full overflow-auto'>
        {errors && <SEO title='GraphQL Error' />}
        {category && <SEO page={category} description={metaDescription} image={category.image} />}

        <div className='bg-red-lighter'>
          <header className='relative header-offset bg-red-lighter'>
            <div className='w-full md:max-w-screen-md px-5 pt-12 pb-16 lg:pt-16 lg:px-12 xl:px-20 relative z-10'>
              <h1 className='display text-purple'>La oss hjelpe deg med {category.title.toLowerCase()}</h1>

              <div>
                {category.description && <BlockContent className='lead mb-8' blocks={category.description} />}

                {products && products.edges.length > 0 && <Button color='green' onClick={() => scrollTo('#produkter')}>Se hva vi kan hjelpe deg med</Button>}
              </div>
            </div>

            {category.image && (
              <Figure
                node={category.image}
                className={`w-full absolute left-0 ${styles.topImage}`}
              />
            )}

            {category.reviews && category.reviews.length > 0 && (
              <div className='w-full pb-8 md:pb-0'>
                <Carousel className='relative pl-4 lg:px-12 xl:px-20'>
                  {category.reviews.map((review, i) => (
                    <div className='w-4/5 lg:w-1/3 lg:max-w-xs flex-shrink-0 pr-4 md:pr-8 max-w-lg mb-8 lg:mb-0' key={i}>
                      <div className='h-full p-4 lg:p-8 bg-white rounded-sm'>
                        <div className='mb-4'><StarRating score={review.score} /></div>
                        <h3 className='h6 font-medium mb-2'>{review.title}</h3>
                        <p className='paragraph-4'>{review.text}</p>
                        <div className='font-bold text-primary'>{review.author}</div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            )}
          </header>

          <div className='bg-red-lighter p-8 pt-0 lg:py-12 xl:py-24'>
            {category.usp && category.usp.length > 0 && (
              <div className='block lg:flex justify-center content-center max-w-screen-xl mx-auto'>
                {category.usp.map((usp, i) => (
                  <Usp text={usp.text} image={usp.image} key={`global-usp-${i}`} />
                ))}
              </div>
            )}

            {(!category.usp || category.usp.length === 0) && (
              <GlobalUSP className='block lg:flex justify-center content-center max-w-screen-xl mx-auto' />
            )}
          </div>

          {category.howItWorksText && (
            <section className='py-20 lg:py-32 px-8 lg:px-0 bg-purple-lighter'>
              <div className='lg:text-center mb-12 lg:mb-20'>
                <h2 className='font-bold text-peach font-serif'>Slik fungerer det</h2>
              </div>

              <div className='lg:flex max-w-lg lg:max-w-screen-xl lg:mx-auto'>
                {category.howItWorksText.map((row, i) => (
                  <div key={i} className='mb-12 lg:mb-0 lg:flex-1 lg:px-12 lg:text-center'>
                    {row.image && <Figure node={row.image} className='w-24 mb-4 lg:mb-8 lg:mx-auto full-width-image' />}
                    <h3 className='h4 font-serif text-peach'>{row.title}</h3>
                    <BlockContent blocks={row.text} className='small' />
                  </div>
                ))}
              </div>
            </section>
          )}

          {products && products.edges.length > 0 && (
            <section id='produkter' className='px-4 py-20 lg:py-32 text-center bg-white'>
              <div className='max-w-screen-lg mx-auto'>
                <h2 className='font-bold text-primary font-serif mb-12'>Hva vi kan hjelpe deg med</h2>

                <div className='flex flex-wrap justify-center mb-10'>
                  {products.edges.map(product => (
                    <div className='w-full md:w-1/2 flex mb-4 md:mb-8' key={product.node.id}>
                      <ProductCard className='h-full w-full md:mx-4' product={product.node} />
                    </div>
                  ))}
                </div>

                <div className='flex flex-col md:flex-row items-center justify-center'>
                  <img src={paymentIcon} alt='' className='m-6' />
                  <div className='h4 mb-0 text-primary font-serif text-center md:text-left max-w-5/6'>Kan vi ikke hjelpe deg, betaler du heller ikke for konsultasjonen</div>
                </div>
              </div>
            </section>
          )}

          {category.experts && category.experts.length > 0 && (
            <section id='eksperter' className='px-8 py-20 lg:py-32 bg-primary-background'>
              <h2 className='font-bold text-primary font-serif text-center mb-12'>Våre leger</h2>

              {category.experts.map((expert, i) => (
                <Physician {...expert} key={i} />
              ))}
            </section>
          )}

          <Marquee></Marquee>

          <Reviews
            className='bg-purple-lighter py-20 lg:py-32'
            title='Dette sier andre om oss'
            showTotals
          />

          {category.faq && (
            <div className='bg-red-lighter py-20 lg:py-32 px-4 md:px-12'>
              <Accordion className='max-w-screen-sm mx-auto' items={faqToAccordion(category.faq)}>
                <h2 className='font-bold text-red font-serif mb-4'>Spørsmål og svar fra dere</h2>
                <p className='lead mb-12'>Her har vi samlet ofte stilte spørsmål. Hvis du ikke finner svar på spørsmålet ditt, kan du sende oss en mail på <a href="mailto:hei@maja.no">hei@maja.no</a>. Husk bare å ikke sende sensitive opplysninger på e-post.</p>
              </Accordion>
            </div>
          )}

          {blog && blog.length > 0 && (
            <div className='bg-white'>
              <div className='py-20 lg:py-32 px-4 max-w-screen-lg mx-auto'>
                <h2 className='font-bold text-primary font-serif text-center mb-8 lg:mb-12'>Artikler fra bloggen</h2>

                <div className='flex flex-wrap md:-mx-4'>
                  {blog.map(node => (
                    <BlogPostPreview className='my-8 w-full md:px-4 md:w-2/4' key={node.id} compact {...node} />
                  ))}
                </div>
              </div>
            </div>
          )}

          {pageDescriptionBlock && pageDescriptionBlock.text && (
            <div className='lg:flex bg-peach-lighter items-center'>
              {pageDescriptionBlock.image && (
                <Figure node={pageDescriptionBlock.image} className='flex-1' aspectRatio={1} />
              )}

              <div className='flex-1 flex flex-col justify-center px-4 py-8 pb-16 md:px-20 md:py-10 lg:px-32 lg:max-w-3/6'>
                {pageDescriptionBlock.title && (
                  <h2 className='font-serif text-yellow'>{pageDescriptionBlock.title}</h2>
                )}
                <BlockContent className='paragraph-3' blocks={pageDescriptionBlock.text} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CategoryTemplate

export const query = graphql`
  query CategoryPageQuery($id: String!) {
    category: sanityCategory(id: {eq: $id}) {
      id
      image {
        asset {
          id
          fluid(maxWidth: 1440) {
            ...GatsbySanityImageFluid
          }
        }
        alt
      }
      description: _rawDescription(resolveReferences:{maxDepth:100})
      title
      slug {
        current
      }
      usp {
        image {
          asset {
            id
            fluid(maxWidth: 64) {
              ...GatsbySanityImageFluid
            }
          }
        }
        text
      }
      reviews {
        title
        score
        text
        author
      }
      howItWorksText {
        title
        text: _rawText(resolveReferences:{maxDepth:100})
        image {
          asset {
            id
            fluid(maxWidth: 200) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      experts {
        id
        name
        image {
          alt
          asset {
            fluid(maxWidth: 452) {
              ...GatsbySanityImageFluid
            }
          }
        }
        description: _rawDescription
        position
      }
      faq {
        question
        _rawAnswer(resolveReferences:{maxDepth:100})
      }
      seo {
        title
        description
      }
    }

    products: allSanityProduct(filter: {
      category: {id: {eq: $id}}
    }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          images {
            asset {
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid
              }
            }
            alt
          }
          category {
            slug {
              current
            }
          }
          lead: _rawLead(resolveReferences:{maxDepth:100})
          defaultPrice
        }
      }
    }

    blog: allSanityBlog(filter: {
      category: {id: {eq: $id}}
    }, sort: {
      fields: _createdAt,
      order: DESC
    }, limit: 2) {
      edges {
        node {
          ...BlogPostPreview
        }
      }
    }

    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      pageDescriptionBlock {
        title,
        text: _rawText(resolveReferences:{maxDepth:100})
        image {
          asset {
            id
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`